<template>
  <FocusTrap>
    <div id="dayplan_card">
      <div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">

            <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

              <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Day Plan</h5>

              <div class="header-elements">
                <form action="#">
                  <div class="form-group-feedback form-group-feedback-right">
                    <input type="date" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off"
                           v-model="working_date" disabled>
                    <div class="form-control-feedback">
                      <i class="icon-search4 font-size-base text-muted"></i>
                    </div>
                  </div>
                </form>
              </div>
            </div>


            <div class="card-body">

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="cmbJoinworker" class="font-weight-semibold">Joint Worker</label>
                    <select id="cmbJoinworker" ref="el_joinworker" class="form-control"
                            v-model="dayplan.jointworker_id">
                      <option value="0" selected>None</option>
                      <option v-for="worker in jointworkers" v-bind:value="worker.id">
                        {{ worker.name }}
                      </option>

                    </select>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label for="cmbWorktype1" class="font-weight-semibold">Session 1</label>
                    <select id="cmbWorktype1" ref="el_worktype1" class="form-control" >
                      <option value="FieldWork" selected>Field Work</option>
                      <option value="Meeting">Meeting</option>
                      <option value="CycleMeeting">Cycle Meeting</option>
                      <option value="Camp">Camp</option>
                      <option value="Deskwork">Deskwork</option>
                      <option value="Leave">Leave</option>
                    </select>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label for="cmbWorktype2" class="font-weight-semibold">Session 2</label>
                    <select id="cmbWorktype2" ref="el_worktype2" class="form-control"
                            v-model="dayplan.session2_worktype" @change="worktype2_changed">
                      <option value="FieldWork" selected>Field Work</option>
                      <option value="Meeting">Meeting</option>
                      <option value="CycleMeeting">Cycle Meeting</option>
                      <option value="Camp">Camp</option>
                      <option value="Deskwork">Deskwork</option>
                      <option value="Leave">Leave</option>
                    </select>
                  </div>
                </div>
              </div>

              <ul class="nav nav-tabs nav-tabs-highlight">
                <li class="nav-item"><a href="#highlight-tabAreas" class="nav-link active" data-toggle="tab">Areas</a>
                </li>
                <li class="nav-item"><a href="#highlight-tabDoctors" class="nav-link" data-toggle="tab">Doctors</a></li>
                <li class="nav-item"><a href="#highlight-tabMedicals" class="nav-link" data-toggle="tab">Medicals</a>
                </li>
                <li class="nav-item"><a href="#highlight-tabGreetings" class="nav-link" data-toggle="tab">Greetings</a>
                </li>
              </ul>

              <div class="tab-content">

                <div class="tab-pane fade show active" id="highlight-tabAreas">
                  <div class="table-responsive">
                    <table id="area_table"
                           class="table  table-hover table-bordered table-condensed table-columned"
                           data-search="false"
                           data-pagination="true"
                           data-show-refresh="false"
                           data-show-columns="false"
                           data-click-to-select="true"
                           data-page-list="[10, 25, 50, 100, ALL]"
                           data-page-size="All"
                           data-show-footer="false"
                           data-toggle="context"
                           data-target=".context-table">
                      <thead>
                      <tr>
                        <th data-field="id" data-class="d-none">id</th>
                        <th data-formatter="runningFormatter" data-width="50">S.No</th>
                        <th data-field="state" data-checkbox="true"></th>
                        <th data-field="name">Area</th>
                        <!--                        <th data-field="id"  data-width="50">id</th>-->
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                      <tfoot>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="tab-pane fade" id="highlight-tabDoctors">
                  <div class="table-responsive">
                    <table id="doctor_table"
                           class="table  table-hover table-bordered table-condensed table-columned"
                           data-search="false"
                           data-pagination="true"
                           data-show-refresh="false"
                           data-show-columns="false"
                           data-click-to-select="true"
                           data-page-list="[10, 25, 50, 100, ALL]"
                           data-show-footer="false"
                           data-page-size="All"
                           data-toggle="context"
                           data-target=".context-table">
                      <thead>
                      <tr>
                        <th data-field="id" data-class="d-none">id</th>
                        <th data-formatter="runningFormatter" data-width="50">S.No</th>
                        <th data-field="state" data-checkbox="true"></th>
                        <th data-field="name">Doctor</th>
                        <th data-field="area.name">Area</th>
                        <th data-field="last_visit">Last Visit</th>
                        <!--                        <th data-field="promoted_products" >Promoted</th>-->
                        <!--                        <th data-field="remarks" >Remarks</th>-->
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                      <tfoot>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="tab-pane fade" id="highlight-tabMedicals">
                  <div class="table-responsive">
                    <table id="medical_table"
                           class="table  table-hover table-bordered table-condensed table-columned"
                           data-search="false"
                           data-pagination="true"
                           data-page-size="All"
                           data-show-refresh="false"
                           data-show-columns="false"
                           data-click-to-select="true"
                           data-page-list="[10, 25, 50, 100, ALL]"
                           data-show-footer="false"
                           data-toggle="context"
                           data-target=".context-table">
                      <thead>
                      <tr>
                        <th data-field="id" data-class="d-none">id</th>
                        <th data-formatter="runningFormatter" data-width="50">S.No</th>
                        <th data-field="state" data-checkbox="true"></th>
                        <th data-field="name">Medical</th>
                        <th data-field="area.name">Area</th>
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                      <tfoot>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="tab-pane fade" id="highlight-tabGreetings">
                  <div class="content">

                    <!-- Horizontal cards -->
                    <div class="mb-3 pt-2">
                      <h6 class="mb-0 font-weight-semibold">
                        <!--          Doctor Brith Day Greetings-->
                        Doctors to be greeted for theirs Birth Day
                      </h6>
                      <span class="text-muted d-block">{{ date_title }}</span>
                    </div>

                    <div class="row">


                      <div class="col-xl-3 col-lg-6" v-for="doctor in doctor_dobs">
                        <div class="card card-body">
                          <div class="media">
                            <div class="mr-3">
                              <a href="#">
                                <img src="static/global_assets/images/demo/users/doctor_logo.jpg" class="rounded-circle"
                                     width="42" height="42" alt="">
                              </a>
                            </div>

                            <div class="media-body">
                              <h6 class="mb-0">{{ doctor.name }}</h6>
                              <span class="text-muted">{{ doctor.qualification }}</span>
                            </div>

                            <div class="ml-3 align-self-center">
                              <div class="list-icons">
                                <div class="dropdown">
                                  <a href="#" class="list-icons-item" data-toggle="dropdown"><i class="icon-menu7"></i></a>
                                  <div class="dropdown-menu dropdown-menu-right">
                                    <a href="#" class="dropdown-item"><i class="icon-comment-discussion"></i> Start chat</a>
                                    <a href="#" class="dropdown-item"><i class="icon-phone2"></i> Make a call</a>
                                    <a href="#" class="dropdown-item"><i class="icon-mail5"></i> Send mail</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!-- /horizontal cards -->


                    <!-- Horizontal cards -->
                    <div class="mb-3 pt-2">
                      <h6 class="mb-0 font-weight-semibold">
                        Doctors to be greeted for theirs Wedding Day
                      </h6>
                    </div>

                    <div class="row">


                      <div class="col-xl-3 col-lg-6" v-for="doctor in doctor_dows">
                        <div class="card card-body">
                          <div class="media">
                            <div class="mr-3">
                              <a href="#">
                                <img src="static/global_assets/images/demo/users/doctor_logo.jpg" class="rounded-circle"
                                     width="42" height="42" alt="">
                              </a>
                            </div>

                            <div class="media-body">
                              <h6 class="mb-0">{{ doctor.name }}</h6>
                              <span class="text-muted">{{ doctor.qualification }}</span>
                            </div>

                            <div class="ml-3 align-self-center">
                              <div class="list-icons">
                                <div class="dropdown">
                                  <a href="#" class="list-icons-item" data-toggle="dropdown"><i class="icon-menu7"></i></a>
                                  <div class="dropdown-menu dropdown-menu-right">
                                    <a href="#" class="dropdown-item"><i class="icon-comment-discussion"></i> Start chat</a>
                                    <a href="#" class="dropdown-item"><i class="icon-phone2"></i> Make a call</a>
                                    <a href="#" class="dropdown-item"><i class="icon-mail5"></i> Send mail</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!-- /horizontal cards -->


                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer col-md-12 text-center">
              <button type="button" class="btn btn-secondary btn-labeled btn-labeled-left" @click="perform_save_action">
                <b><i class="icon-reading"></i></b>
                Update
              </button>

            </div>

          </div>
        </div>
      </div>

    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'DayPlanView',
  store,
  data () {
    return {
      working_date: moment(),
      today_areas: [],
      dayplan: JSON.parse('{"id":0,"master_id":0,"status":"Active","doc_date":"2000-01-01","session1_worktype":"FieldWork","session2_worktype":"FieldWork","has_jointworker":false,"fieldpeople_id":0,"jointworker_id":0,"remarks":"","change_remark":"","is_area_changed":false,"jointworker":{},"areas":[],"doctors":[],"medicals":[]}'),
      date_title: '',
      doctor_dobs: [],
      doctor_dows: [],
      jointworkers: [],
      doctors: [],
      medicals: [],
    }
  },
  created () {
    this.$data.working_date = this.$store.state.user.working_date

  },
  mounted () {
    const self = this;
    self.$data.dayplan.working_date = self.$store.state.user.working_date;

    //dayplan_card
    $('#area_table').bootstrapTable();
    $('#doctor_table').bootstrapTable();
    $('#medical_table').bootstrapTable();

    $('#area_table').bootstrapTable('load', self.$store.state.user.areas);

    $('#area_table').on('check.bs.table', function (e, row) {
      self.load_doctors_medicals();
    });

    $('#area_table').on('uncheck.bs.table', function (e, row) {
      self.load_doctors_medicals();
    });

    self.loadDayPlan();
    self.loadGreetings();
    self.loadJointworkers();

    $('.nav-tabs a').on('shown.bs.tab', function (e) {
      let current_tab = e.target;
    });

  },
  watch:{
    today_areas(){

    }
  },
  methods: {
    worktype1_changed(){
      alert('worktype1_changed');
    },
    loadDayPlan () {
      const self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      try {

        $('#dayplan_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })

        // alert(`${process.env.VUE_APP_ROOT_API}api/dayplan/emp/${self.$store.state.user.id}/${self.$data.working_date}`);

        self.$data.today_areas = []
        self.$data.dayplan = JSON.parse('{"id":0,"master_id":0,"status":"Active","doc_date":"2000-01-01","session1_worktype":"FieldWork","session2_worktype":"FieldWork","has_jointworker":false,"fieldpeople_id":0,"jointworker_id":0,"remarks":"","change_remark":"","is_area_changed":false,"jointworker":{},"areas":[],"doctors":[],"medicals":[]}')
        fetch(`${process.env.VUE_APP_ROOT_API}api/dayplan/emp/${self.$store.state.user.id}/${self.$data.working_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#dayplan_card').unblock();

          if (resp.ok) {

            console.log(JSON.stringify(resp.data));

            if (!_.isNull(resp.data)) {

              self.$data.dayplan.doc_date = resp.data.doc_date;
              self.$data.dayplan.working_date = resp.data.work_date;

              self.$data.dayplan.session1_worktype = resp.data.session1_worktype;
              self.$data.dayplan.session2_worktype = resp.data.session2_worktype;

              self.$data.dayplan.fieldpeople_id = resp.data.fieldpeople_id;
              self.$data.dayplan.jointworker_id = resp.data.jointworker_id;

              self.$data.today_areas = [];

              if ('areas1' in resp.data) {
                resp.data.areas1.forEach((a) => {
                  self.$data.today_areas.push(a.area_id)
                })
              }

              if ('areas2' in resp.data) {
                resp.data.areas2.forEach((a) => {
                  self.$data.today_areas.push(a.area_id)
                })
              }

              if ('areas' in resp.data) {
                resp.data.areas.forEach((a) => {
                  self.$data.today_areas.push(a.area_id)
                })
              }

              let doctor_ids = []
              if ('doctors' in resp.data) {
                resp.data.doctors.forEach((detail) => {
                  doctor_ids.push(detail.doctor.id)
                })
              }

              let medical_ids = []
              if ('medicals' in resp.data) {
                resp.data.medicals.forEach((detail) => {
                  medical_ids.push(detail.medical.id);
                });
              }


              $('#area_table').bootstrapTable('checkBy', { field: 'id', values: self.$data.today_areas });
              // $('#doctor_table').bootstrapTable('load', self.$data.doctors);
              $('#doctor_table').bootstrapTable('checkBy', { field: 'id', values: doctor_ids });
              // $('#medical_table').bootstrapTable('load', self.$data.medicals);
              $('#medical_table').bootstrapTable('checkBy', { field: 'id', values: medical_ids });

            }

          } else {
            console.log(JSON.stringify(resp.data));
            // swal({ title: 'Oops!', text: resp.msg, type: 'error' });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#dayplan_card').unblock()
        })
      } catch (e) {
        alert(e)
      }

    },
    loadDayPlanDoctors () {
      const self = this

      try {
        // $('#doctor_table').bootstrapTable('load', self.$store.state.user.doctors);
        $('#doctor_table').bootstrapTable('load', self.$data.doctors)
      } catch (e) {
        alert(e)
      }
    },
    loadDayPlanMedicals () {
      const self = this
      try {
        $('#medical_table').bootstrapTable('load', self.$data.medicals);
      } catch (e) {
        alert(e)
      }
    },
    loadGreetings () {
      const self = this

      try {

        let today = moment(new Date())

        this.$store.state.user.doctors.forEach((doctor) => {

          if (moment(doctor.dob).date() == today.date() && moment(doctor.dob).month() == today.month()) {
            self.$data.doctor_dobs.push(doctor)
          }

          if (moment(doctor.dow).date() == today.date() && moment(doctor.dow).month() == today.month()) {
            self.$data.doctor_dows.push(doctor)
          }

        })

        self.$data.date_title = moment(self.$data.working_date).format('ll')
      } catch (e) {
        alert(e)
      }
    },
    loadJointworkers () {
      const self = this

      try {
        $('#cmbJoinworker').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        fetch(`${process.env.VUE_APP_ROOT_API}api/employees`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#cmbJoinworker').unblock()

          if (resp.ok) {
            // console.log(JSON.stringify(resp.data));
            if (_.isArray(resp.data)) {
              self.$data.jointworkers = resp.data
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          $('#cmbJoinworker').unblock()
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          // $('#cmbJoinworker').unblock();
        })
      } catch (e) {
        alert(e)
      }
    },
    load_doctors_medicals(){
      const self = this;

      self.$data.today_areas = [];
      let areas = $('#area_table').bootstrapTable('getSelections');

      areas.forEach((area)=>{
        self.$data.today_areas.push(area.id);
      });

      self.$data.doctors = [];
      self.$data.medicals = [];

      self.$store.state.user.doctors.forEach((doctor) => {
        if (self.$data.today_areas.indexOf(doctor.area.id) !== -1) {
          self.$data.doctors.push(doctor);
        }
      });


      self.$store.state.user.medicals.forEach((medical) => {
        if (self.$data.today_areas.indexOf(medical.area.id) !== -1) {
          self.$data.medicals.push(medical);
        }
      });


      $('#doctor_table').bootstrapTable('load', self.$data.doctors);
      $('#medical_table').bootstrapTable('load', self.$data.medicals);

    },
    perform_save_action () {
      const self = this;
      try {

        $('#dayplan_card').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.dayplan.doc_date = self.$store.state.user.working_date;
        self.$data.dayplan.areas = $('#area_table').bootstrapTable('getSelections');
        self.$data.dayplan.doctors = $('#doctor_table').bootstrapTable('getSelections');
        self.$data.dayplan.medicals = $('#medical_table').bootstrapTable('getSelections');

        console.log(JSON.stringify(self.$data.dayplan));

        const requestOptions = {
          method: (self.$data.dayplan.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.dayplan)
        }

        fetch(`${process.env.VUE_APP_ROOT_API}api/dayplan`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({
              title: 'Success!', text: resp.msg, type: 'success', onClose: () => {
                $('#cmbWorktype1').focus();
              }, timer: 1500
            });
            self.dayplan = JSON.parse('{"id":0,"master_id":0,"status":"Active","doc_date":"2000-01-01","session1_worktype":"FieldWork","session2_worktype":"FieldWork","has_jointworker":false,"fieldpeople_id":0,"jointworker_id":0,"remarks":"","change_remark":"","is_area_changed":false,"jointworker":{},"areas":[],"doctors":[],"medicals":[]}');
            self.$emit('dayplan_saved', resp.data);

          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error', timer: 3000 });
          }
          $('#dayplan_card').unblock();

        }).catch(function (err) {
          // console.log(err.toString());
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer: 3000 });
        }).finally(function () {
          $('#dayplan_card').unblock();
        })

      } catch (e) {
        alert(e)
      } finally {

      }
    },
  }
}
</script>

<style scoped>

</style>
